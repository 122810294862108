<template>
  <section>
    <h2>Наши РЦ</h2>

    <p v-if="!networks.length">
      {{ emptyTradingsNetworksMessage }}
    </p>

    <template v-else>
      <rcc-tabs-panel
        v-model="activeTabIndex"
        :tabs="networks"
        class="tradings-networks-tabs"
      />

      <rcc-tab-view
        :tabs="networks"
        :active-tab-index="activeTabIndex"
      >
        <template #default="{ tab }">
          <rcc-return-points-view
            :regions="regions"
            :network-id="tab.id"
            :selected-return-points="selectedReturnPoints"
            @selected-points-change="handleSelectedPointsChange"
          />
        </template>
      </rcc-tab-view>
    </template>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import RccTabsPanel from 'Components/ui/tabs/tabs-panel'
import RccTabView from 'Components/ui/tabs/tab-view'
import RccReturnPointsView from './return-points-view'

export default {
  name: 'RccReturnPointsList',

  components: {
    RccTabsPanel,
    RccTabView,
    RccReturnPointsView
  },

  props: {
    networks: {
      type: Array,
      default: () => []
    },

    selectedReturnPoints: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      activeTabIndex: 0,
      regions: []
    }
  },

  computed: {
    ...mapGetters(['role']),

    isAdmin() {
      return this.role === this.$constants.users.admin
    },

    emptyTradingsNetworksMessage() {
      return 'К поставщику не привязано ни одной торговой сети для отображения. ' +
      (
        this.isAdmin ?
          'Выберите торговые сети для отображения из списка выше.' :
          'Обратитесь к администратору для добавления торговых сетей'
      )
    }
  },

  created() {
    this.$apiMethods.regions.allList()
      .then(({ items }) => {
        this.regions = items
      })
  },

  methods: {
    handleSelectedPointsChange(points) {
      this.$emit('update:selected-return-points', points)
    }
  }
}
</script>
